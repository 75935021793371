


























import Vue from 'vue'

export default Vue.extend({
  name: 'EmployeePicker',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    employees: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  methods: {
    emitChange (value: string|number): void {
      this.$emit('change', value)
    }
  }
})
