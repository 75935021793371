import mixins from 'vue-typed-mixins'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import BookingEvent from '@/calendesk/models/BookingEvent'
import FlexibleBooking from '@/calendesk/sections/section/shared/mixins/FlexibleBooking'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import SelectableService from '@/calendesk/models/SelectableService'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import BookingRawSlot from '@/calendesk/models/BookingRawSlot'
import parseRawBookingTimeSlots from '@/calendesk/tools/parseRawBookingTimeSlots'

export default mixins(FlexibleBooking).extend({
  data: () => ({
    timeSpots: null as Record<string, any> | null,
    events: [] as Array<BookingEvent>,
    selectedTime: null as string | null,
    showDatePicker: false,

    // Can be used by some pickers to manually sync dates
    // It should always be the same what is selectedDate
    pickerDate: null as string|null
  }),
  watch: {
    preferredEmployeeIdFromTheLastBooking (val) {
      if (this.selectedSelectableService) {
        this.selectedEmployeeId = val
      }
    },
    selectedSelectableService (newValue: SelectableService | null, oldValue: SelectableService | null) {
      this.checkIfSelectedEmployeeNeedsUpdate()
      this.checkIfSelectedLocationNeedsUpdate()
      if (!newValue || !oldValue ||
        (newValue && oldValue && JSON.stringify(newValue) !== JSON.stringify(oldValue))) {
        this.firstDateSet = false
        this.isFetchingTimeSlots = true
        this.reloadPreferredEmployee()
        this.checkIfCanResetDate()
        this.reloadEventsDebounce()
      }
    },
    selectedDate (newDate: string, oldDate: string) {
      if (newDate !== oldDate && this.numberOfHoursForDate(newDate) === 0) {
        this.isFetchingTimeSlots = true
        this.reloadEventsDebounce()
      }

      this.pickerDate = this.selectedDate
    },
    selectedEmployeeId (newEmployeeId: number | null, oldEmployeeId: number | null) {
      if (newEmployeeId !== oldEmployeeId) {
        this.firstDateSet = false
        this.isFetchingTimeSlots = true
        this.checkIfCanResetDate()
        this.reloadEventsDebounce()
      }
    },
    selectedLocation (newLocation: ServiceLocation | null, oldLocation: ServiceLocation | null) {
      if (!newLocation || !oldLocation || newLocation.id !== oldLocation.id) {
        this.firstDateSet = false
        this.isFetchingTimeSlots = true
        this.checkIfCanResetDate()
        this.reloadEventsDebounce()
      }
    },
    shouldReload (reload) {
      if (reload) {
        this.isFetchingTimeSlots = true
        this.reloadEventsDebounce()
        this.setReloadAllBookings(false)
        this.bookingCartSlots = []
      }
    },
    customerTimeZone (newTz: string | null, oldTz: string | null) {
      if (newTz !== oldTz) {
        this.isFetchingTimeSlots = true
        this.reloadEventsDebounce()
      }
    }
  },
  mounted () {
    this.setupCalendar()
  },
  computed: {
    showServiceColumn (): boolean {
      return (typeof this.data.configuration.wb_show_service_column__checkbox__ === 'undefined' ||
        !!this.data.configuration.wb_show_service_column__checkbox__)
    },
    mergedTimeSpots (): Array<BookingTimeSlot> {
      let slots: BookingRawSlot[] = []

      if (this.timeSpots) {
        if (this.selectedEmployeeId) {
          if (this.timeSpots[this.selectedEmployeeId] && this.timeSpots[this.selectedEmployeeId][this.selectedDate as string]) {
            const dateValues = this.timeSpots[this.selectedEmployeeId][this.selectedDate as string]
            if (dateValues && dateValues.length > 0) {
              slots = [
                ...slots,
                ...dateValues.map((slot: BookingRawSlot) => (
                  {
                    ...slot,
                    date: this.selectedDate,
                    availableEmployeeIds: [this.selectedEmployeeId]
                  }))]
            }
          }
        } else {
          for (const key of Object.keys(this.timeSpots as {})) {
            const dateValues = this.timeSpots[key][this.selectedDate as string]
            if (dateValues && dateValues.length > 0) {
              slots = [
                ...slots,
                ...dateValues.map((slot: BookingRawSlot) => (
                  {
                    ...slot,
                    date: this.selectedDate,
                    availableEmployeeIds: [parseInt(key, 10)]
                  }))]
            }
          }
        }

        return parseRawBookingTimeSlots(slots, this.serviceMaxPeople)
      }

      return []
    },
    firstAvailableDate (): string | null {
      const dates: Set<string> = new Set()
      if (this.timeSpots) {
        if (this.selectedEmployeeId && this.timeSpots[this.selectedEmployeeId]) {
          for (const key of Object.keys(this.timeSpots[this.selectedEmployeeId] as {})) {
            const hours = this.timeSpots[this.selectedEmployeeId][key]
            if (hours && hours.some((slot: any) => !slot.booked)) {
              dates.add(key)
            }
          }
        } else {
          for (const employeeId of Object.keys(this.timeSpots as {})) {
            for (const key of Object.keys(this.timeSpots[employeeId] as {})) {
              const hours = this.timeSpots[employeeId][key]
              if (hours && hours.some((slot: any) => !slot.booked)) {
                dates.add(key)
              }
            }
          }
        }
      }

      const result = [...dates].sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime()
      })

      if (result.length > 0) {
        return result[0]
      }

      return null
    }
  },
  methods: {
    setDate (date: string) {
      this.selectedDate = date
      this.showDatePicker = false

      if (this.numberOfHoursForDate(date) === 0) {
        this.isFetchingTimeSlots = true
        this.reloadEventsDebounce()
      }
    },
    checkIfSelectedEmployeeNeedsUpdate () {
      if (this.serviceEmployees.length === 1 && this.selectedEmployeeId !== this.serviceEmployees[0].id) {
        this.selectedEmployeeId = this.serviceEmployees[0].id
      } else if (this.selectedEmployeeId) {
        const availableEmployees = this.serviceEmployees.map((e) => e.id)
        if (!availableEmployees.includes(this.selectedEmployeeId)) {
          this.selectedEmployeeId = null
        }
      }
    },
    checkIfSelectedLocationNeedsUpdate () {
      if (this.selectedLocation) {
        const availableLocations = this.serviceLocations.map((e) => e.id)
        if (!availableLocations.includes(this.selectedLocation.id)) {
          this.selectedLocation = null
        }
      }
    },
    checkIfCanResetDate () {
      if (!this.lockDateChange) {
        const date = this.requestedDate ?? this.$moment().format(DateTimeFormats.FULL)
        this.setDate(date)
      }
    },
    isDateAllowed (date: string) {
      return this.numberOfHoursForDate(date) > 0
    },
    numberOfHoursForDate (date: string): number {
      let result = 0

      if (this.timeSpots) {
        if (this.selectedEmployeeId) {
          if (this.timeSpots[this.selectedEmployeeId] && this.timeSpots[this.selectedEmployeeId][date]) {
            const dateValues = this.timeSpots[this.selectedEmployeeId][date]
            if (dateValues && dateValues.length > 0) {
              result = this.countSpots(dateValues)
            }
          }
        } else {
          for (const key of Object.keys(this.timeSpots as {})) {
            const dateValues = this.timeSpots[key][date]
            if (dateValues && dateValues.length > 0) {
              result += this.countSpots(dateValues)
            }
          }
        }
      }

      return result
    },
    reloadEventsDebounce () {
      this.timeSpots = null
      this.debounce(this.reloadEvents, 400)()
    },
    reloadEvents () {
      if (this.showLocationError) {
        this.isFetchingTimeSlots = false
        return
      }

      if (this.selectedSelectableService && this.selectedDate) {
        this.isFetchingTimeSlots = true

        const eventsRequestStartDate = this.getEventsRequestStartDate()
        const maxBookingDaysForService = this.selectedSelectableService.service.getMaxBookingDays()
        const adjustedNumberOfDays = this.getAdjustedEventsRequestStartDate(eventsRequestStartDate, maxBookingDaysForService)

        if (adjustedNumberOfDays <= 0) {
          this.cleanEventsFetchSettings()
          this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedEmployeeId)
          return
        }

        this.fetchAvailableBookingSlots({
          used_slots: 1,
          booked_slots: this.showBookedSlots ? 1 : 0,
          number_of_days: adjustedNumberOfDays,
          service_id: this.selectedSelectableService.service.id,
          employee_ids: this.serviceEmployees.map(employee => employee.id).join(','),
          start_date: eventsRequestStartDate,
          service_type_id: this.selectedSelectableService.serviceType?.id || null,
          customer_time_zone: this.customerTimeZone,
          location_id: this.selectedLocation?.locationId || null
        }).then((response: Record<string, any>) => {
          if (this.hasAvailableTimeSlots(response, this.selectedEmployeeId)) {
            // Got response with time slots
            this.timeSpots = response
            this.cleanEventsFetchSettings()
            this.validatePreferredEmployeeIdFromTheLastBooking(this.timeSpots, this.selectedEmployeeId)

            if (!this.firstDateSet && this.firstAvailableDate) {
              this.firstDateSet = true
              this.selectedDate = this.firstAvailableDate
            }
          } else if (this.canRetryEventsFetch) {
            // No available time slots, retry.
            this.fetchAttempts++
            this.currentFetchStartDate = this.getNextFetchDate

            this.reloadEvents() // Fetch again if no slots are available
          } else {
            // Giving up, no available slots after retrying.
            this.cleanEventsFetchSettings()
            this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedEmployeeId)
          }
        }).catch(error => {
          this.$log.info('flexibleBookingV1@fetchAvailableBookingSlots@catch', error)
          this.cleanEventsFetchSettings()
          this.validatePreferredEmployeeIdFromTheLastBooking(null, this.selectedEmployeeId)
        })
      }
    },
    bookSelectedTime (slot: BookingTimeSlot) {
      if (this.selectedSelectableService) {
        this.userRequestedEmployee = !!this.selectedEmployeeId
        const employee = this.getSelectedEmployeeForTime(slot)

        if (employee) {
          const event = new BookingEvent(
            this.selectedSelectableService.service,
            employee,
            this.selectedSelectableService.serviceType,
            this.selectedDate,
            slot.time as string,
            null,
            null,
            null,
            this.customerTimeZone,
            this.userRequestedEmployee,
            this.selectedLocation)

          this.openDialog(new Dialog(
            true,
            DialogTypes.BOOKING_FORM_DIALOG,
            null,
            DialogSize.MIDDLE,
            false,
            'center',
            {
              events: [event],
              configurationData: this.data
            },
            true))
        }
      }
    },
    getSelectedEmployeeForTime (slot: BookingTimeSlot): Employee | null {
      if (this.timeSpots) {
        const availableEmployeeIds: Set<number> = new Set()

        // Collect available employee IDs based on time spots
        for (const [key, dates] of Object.entries(this.timeSpots)) {
          const employeeDates = dates[this.selectedDate]
          if (employeeDates) {
            const isSlotAvailable = employeeDates.some((item: BookingTimeSlot) => item.time === slot.time)
            if (isSlotAvailable) {
              availableEmployeeIds.add(parseInt(key, 10))
            }
          }
        }

        // Filter employees based on cart bookings
        const sameSlots = this.getTheSameSlots(slot)
        const filteredEmployeeIds = Array.from(availableEmployeeIds).filter(id => {
          const maxBookings = slot.employeeMaxBookings[id] || 0
          const currentBookings = sameSlots.filter(cartSlot => cartSlot.event.employee.id === id).length
          return currentBookings < maxBookings
        })

        // Try to return the preferred employee from the last booking if available
        if (this.preferredEmployeeIdFromTheLastBooking && filteredEmployeeIds.includes(this.preferredEmployeeIdFromTheLastBooking)) {
          const preferredEmployee = this.serviceEmployees.find((employee: Employee) => employee.id === this.preferredEmployeeIdFromTheLastBooking)
          if (preferredEmployee) {
            return preferredEmployee
          }
        }

        // Try to return the selected employee if available
        if (this.selectedEmployeeId && filteredEmployeeIds.includes(this.selectedEmployeeId)) {
          const selectedEmployee = this.serviceEmployees.find((employee: Employee) => employee.id === this.selectedEmployeeId)
          if (selectedEmployee) {
            return selectedEmployee
          }
        }

        // Check if we can find an employee that is already in the cart
        const cartEmployeeIds = sameSlots.map(cartSlot => cartSlot.event.employee.id)
        const cartEmployeeId = cartEmployeeIds.find(id => filteredEmployeeIds.includes(id))
        if (cartEmployeeId) {
          const cartEmployee = this.serviceEmployees.find((employee: Employee) => employee.id === cartEmployeeId)
          if (cartEmployee) {
            return cartEmployee
          }
        }

        // If no preferred employee, select a random one from the filtered list
        if (filteredEmployeeIds.length > 0) {
          const randomEmployeeId = this.getRandomItemFromSet(new Set(filteredEmployeeIds))
          const randomEmployee = this.serviceEmployees.find((employee: Employee) => employee.id === randomEmployeeId)
          if (randomEmployee) {
            return randomEmployee
          }
        }
      }

      return null
    },
    setupCalendar () {
      if (!this.calendarInitialServiceSelectionSupported) {
        this.selectFirstServiceAsDefault()
        this.checkIfSelectedEmployeeNeedsUpdate()
        this.checkIfSelectedLocationNeedsUpdate()

        this.$nextTick(() => {
          this.reloadEventsDebounce()
        })
      }
    }
  }
})
